<template>
  <div>
    <form class="login col-md-4 offset-md-4" @submit.prevent="login">
      <div class="my-5">
        <h1>Belépés</h1>
        <label>Felhasználónév</label>
        <input required v-model="identifier" type="text" class="form-control" placeholder="Name" />
        <label>Jelszó</label>
        <input
            required
            v-model="password"
            type="password"
            class="form-control"
            placeholder="Password"
        />
        <hr />
        <b-button variant="success" block type="submit">Belépés</b-button>
      </div>

    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      identifier: "felhaszno@temeto.ro",
      password: "123456",
    };
  },
  methods: {
    login: function() {
      let identifier = this.identifier;
      let password = this.password;
      this.$store
        .dispatch("login", { identifier, password })
        .then(() =>{
          this.$toasted.show('A bejelntkezes sikeres volt.', {
            duration: 2000,
          })
          this.$router.push("/")
        })
        .catch(() => {
          this.$toasted.show('A bejelntkezes sikertelen volt. Hibas felhasznalo vagy jelszo.', {
            duration: 2000,
          })
        });
    },
  },
};
</script>
